<template>
  <div id="app">
    <div class="timeline">
      <h2 style="margin-bottom: -50px">Напоминание, Аружан</h2>
      <Timeline/>
    </div>
  </div>
</template>

<script>
import Timeline from "@/components/Timeline"

export default {
  name: 'App',
  components: {
    Timeline
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  justify-content: center;

}

.timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  height: 600px;
}
</style>
