<template>
  <div class="timeline">
    <div class="point">
      <h4 class="left-side">Начало учёбы в 10 классе</h4>
      <svg height="100" width="100%" class="green-circle">
        <circle cx="50%" cy="50%" r="10" fill="#42b983" stroke="#2c3e50" stroke-width="2px"/>
      </svg>
      <p class="left-side">1 Сентября 2014</p>
    </div>

    <div class="point">
      <p class="right-side">24 Сентября 2028</p>
      <svg height="100%" width="100%">
        <circle cx="50%" cy="50%" r="10" fill="#42b983" stroke="#2c3e50" stroke-width="2px"/>
      </svg>
      <h4 class="right-side">30 лет</h4>
    </div>

    <!--  Red circle  -->
    <div id="you-here" :style="{ marginTop: hereOffset + 'px', zIndex: 1}">
      <svg height="100%" width="100%">
        <circle cx="50%" cy="50%" r="5" fill="red" stroke="#2c3e50" stroke-width="2px"/>
      </svg>
    </div>

    <div id="point-of-no-return">
      <svg height="100%" width="100">
        <line x1="40" y1="50%" x2="60" y2="50%" stroke="black" stroke-width="3px"></line>
      </svg>
      <p style="margin-top: 40px; margin-left: -40px; font-size: 0.9em" v-if="hereOffset > 210">Линия невозврата</p>
    </div>

    <div style="margin-top: -100px; margin-bottom: 100px">
      С линии невозврата прошло:<br>
      <strong>
        {{ days }} дней, {{ hours }} часов, {{ minutes }} минут, {{ seconds }} секунд
      </strong>
    </div>

    <div style="max-width: 300px">
      Вспомни, сколько всего хорошего произошло за это время<br>и с уверенностью смотри в будущее ❤
    </div>

  </div>
</template>

<script>
export default {
  name: "Timeline",
  computed: {
    hereOffset() {
      let offset = 50 // 50px initial value, 385px max
      let maxLength = 385

      let differencePrevious = this.currentDate - this.previousDate
      let totalTime = this.futureDate - this.previousDate

      let timePassedPercentage = differencePrevious / totalTime

      let pxLength = maxLength - offset // 385-50 = 335
      offset = timePassedPercentage * pxLength + offset
      console.log('px offset is ' + offset)
      return offset
    },
    timePassed() {
      let middleDate = ((this.futureDate - this.previousDate) / 2) + this.previousDate.getTime()
      let timePassed = this.currentDate - middleDate
      console.log(timePassed + ' ms passed')
      return timePassed
    }
  },
  data() {
    return {
      currentDate: new Date(),
      previousDate: new Date('September 1, 2014 08:30'),
      futureDate: new Date('September 24, 2028 00:00'),

      interval: null,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      intervals: {
        second: 1000,
        minute: 1000 * 60,
        hour: 1000 * 60 * 60,
        day: 1000 * 60 * 60 * 24
      }
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      this.currentDate = new Date()
      this.updateDiffs();
    }, 1000);
    this.updateDiffs();
  },
  destroyed() {
    clearInterval(this.interval);
  },

  methods: {
    updateDiffs() {
      //lets figure out our diffs
      let diff = Math.abs(this.timePassed);
      this.days = Math.floor(diff / this.intervals.day);
      diff -= this.days * this.intervals.day;
      this.hours = Math.floor(diff / this.intervals.hour);
      diff -= this.hours * this.intervals.hour;
      this.minutes = Math.floor(diff / this.intervals.minute);
      diff -= this.minutes * this.intervals.minute;
      this.seconds = Math.floor(diff / this.intervals.second);
    }
  }
}
</script>

<style scoped>

.timeline {
  padding-top: 30px;
}

.timeline::before {
  margin-top: 90px;
  content: '';
  background: #2c3e50;
  width: 5px;
  height: 400px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}


h4 {
  padding: 10px;
  border: solid #2c3e50;
  border-radius: 10px;
  box-shadow: 3px 3px 3px #42b983;
  display: flex;
  align-items: center;
  justify-content: center;
}

h4.left-side {
  margin: 50% -25% 50% 50%;

}

h4.right-side {
  margin: 50% 50% 50% -25%;
}

.point {
  display: grid;
  align-self: center;
  justify-self: center;
  grid-template-columns: 1fr 1fr 1fr;
  height: 240px;
  margin-bottom: 100px;
}

p {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

p.left-side {
  margin-left: -80%;
}

p.right-side {
  margin-right: -80%;
}

#you-here {
  position: absolute;
}

#point-of-no-return {
  margin-left: 100px;
  margin-top: 217px;
  position: absolute;
  width: 200px;
  display: flex;
  align-content: space-evenly;
  justify-content: center;
}

.green-circle {
  margin-top: 45%;
}
</style>